
import { defineComponent } from 'vue';
import ErpList from '@/components/ErpList/ErpList.vue';
import useListData from './components/useAuditListData';
import router from '@/router';
export default defineComponent({
  name: 'auditStatement',
  components: { ErpList },
  props: {},
  setup() {
    const { columnList, tableRef } = useListData();
    // 刷新列表
    const refreshTable = (status = false) => {
      tableRef.value.refreshTable(status);
    };

    // 更新结算单
    const back = () => {
      const path = '/erp/financial/signlist';
      router.push(path);
    };

    return {
      back,
      columnList,
      tableRef,
      refreshTable,
    };
  },
});
