import { ErpTableOptions } from '@/types/type';
import { showFiles } from '@/utils/publicMethods';

import { defineComponent, ref } from 'vue';
export default () => {
  const tableRef = ref();
  const columnList: ErpTableOptions[] = [
    {
      label: '结算单号',
      prop: 'sellStmtNo', // 属性
      minWidth: 160,
      value: '',
      type: 'input',
    },
    {
      label: '销售合同号',
      prop: 'businessContractNo', //
      minWidth: 160,
      value: '',
      type: 'input',
    },
    {
      label: '客户',
      prop: 'customerName', // 属性
      minWidth: 160,
      value: '',
      type: 'input',
    },
    {
      label: '签约主体',
      prop: 'contractPrincipalName', // 属性
      minWidth: 160,
      value: '',
      type: 'input',
    },
    {
      label: '计费方式',
      prop: 'settleType',
      propDesc: 'settleTypeDesc',
      minWidth: 130,
      value: [],
      type: 'multiSelect',
      options: 'settle_type',
    },
    {
      label: '结算日期',
      prop: 'settleDate',
      minWidth: 128,
      value: [],
      type: 'time',
      filterProp: ['settleStartDate', 'settleEndDate'],
    },
    {
      label: '确认人',
      prop: 'confirmOperator',
      minWidth: 160,
      value: '',
      type: 'input',
    },
    {
      label: '确认时间',
      prop: 'confirmTime',
      minWidth: 180,
      value: [],
      type: 'time',
      filterProp: ['confirmTimeStartTime', 'confirmTimeEndTime'],
    },
    {
      label: '确认说明',
      prop: 'confirmDesc',
      minWidth: 120,
      value: '',
      type: 'input',
      isSort: false,
      isFilter: false,
    },
    {
      label: '审核状态',
      prop: 'auditStatus',
      propDesc: 'auditStatusDesc',
      minWidth: 130,
      value: [],
      type: 'multiSelect',
      options: 'sell_stmt_audit_status',
    },
    {
      label: '审核说明',
      prop: 'auditDesc',
      minWidth: 180,
      value: '',
      type: 'input',
      isSort: false,
      isFilter: false,
    },
    {
      label: '审核人',
      prop: 'auditOperator',
      minWidth: 160,
      value: '',
      type: 'input',
    },
    {
      label: '审核时间',
      prop: 'auditTime',
      minWidth: 160,
      value: [],
      type: 'time',
      filterProp: ['auditTimeStartTime', 'auditTimeEndTime'],
    },
    {
      label: '结算单',
      prop: 'excelName',
      minWidth: 88,
      value: '',
      type: 'input',
      isFilter: false,
      isSort: false,
      component: defineComponent({
        template: `
        <el-button
        size="mini"
        plain
        type="primary"
        @click='onlineView(row)'
      >查看</el-button>
          `,
        props: ['row', 'col', 'column'],
        emits: ['noop'],
        setup() {
          const onlineView = (row) => {
            showFiles(row.excelName, row.excelUrl);
          };
          return { onlineView };
        },
      }),
    },
  ];
  return { columnList, tableRef };
};
